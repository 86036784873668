var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { collapsed: _vm.collapsed },
      attrs: { id: "stepline-component" },
    },
    [
      _vm.stageLoaded && _vm.currentStage
        ? _c("div", [
            _c(
              "div",
              { attrs: { id: "stepline-exit-stageline" } },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "root" } } },
                  [
                    _c("fa-icon", {
                      staticClass: "fa-xs",
                      attrs: { icon: "chevron-left" },
                    }),
                    !_vm.collapsed
                      ? _c("span", [_vm._v(" Exit Stageline")])
                      : _c("span", [_vm._v(" Exit")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "stepline-start-over mt-0 mb-3 flex-column" },
              [
                !_vm.collapsed
                  ? _c(
                      "b-button",
                      {
                        staticClass: "next-button",
                        attrs: {
                          id: "start-over-button",
                          variant: "primary",
                          disabled: false,
                        },
                        on: {
                          click: _vm.showRestartStagelineConfirmationModal,
                        },
                      },
                      [
                        _c("span", { staticClass: "v-padding-point-5" }, [
                          _vm._v("\n          Start Over\n        "),
                        ]),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        attrs: { id: "start-over-link" },
                        on: {
                          click: _vm.showRestartStagelineConfirmationModal,
                        },
                      },
                      [
                        _c("span", [
                          _vm._v("\n          Start\n          Over\n        "),
                        ]),
                      ]
                    ),
              ],
              1
            ),
            _c(
              "div",
              { attrs: { id: "stepline-company-name" } },
              [
                !_vm.collapsed
                  ? _c("h3", [
                      _vm._v(
                        "\n        " + _vm._s(_vm.company.name) + "\n      "
                      ),
                    ])
                  : _c("h3"),
                !_vm.collapsed &&
                ["form", "expand", "dissolve"].includes(_vm.stagelineType)
                  ? [
                      _c(
                        "div",
                        { attrs: { id: "stepline-current-jurisdiction" } },
                        [
                          _vm.stagelineType === "expand"
                            ? _c("span", [_vm._v("EXPANDING TO")])
                            : _vm._e(),
                          _vm.stagelineType === "form"
                            ? _c("span", [_vm._v("FORMING IN")])
                            : _vm._e(),
                          _vm.stagelineType === "dissolve"
                            ? _c("span", [_vm._v("DISSOLVING IN")])
                            : _vm._e(),
                          _c("br"),
                          _c(
                            "span",
                            {
                              attrs: {
                                id: "stepline-current-jurisdiction-name",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.jurisdiction.state_province_region)
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c("div", { attrs: { id: "stepline-stage-number" } }, [
              _vm._v(
                "\n      STAGE " + _vm._s(_vm.currentStageIndex + 1) + "\n    "
              ),
            ]),
            !_vm.collapsed
              ? _c("div", { attrs: { id: "stepline-stage-title" } }, [
                  _vm._v(
                    "\n      " + _vm._s(_vm.currentStage.title) + "\n    "
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { attrs: { id: "stepline-step-list" } },
              _vm._l(_vm.filteredSteps, function (step) {
                return _c(
                  "div",
                  {
                    key: step.id,
                    staticClass: "step",
                    class: {
                      previous: _vm.stepIsPrevious(step),
                      active: _vm.stepIsCurrent(step),
                      complete:
                        _vm.stepIsComplete(step) && !_vm.stepIsCurrent(step),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.gotoStep(step)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "indicator" },
                      [
                        _vm.stepIsComplete(step) && !_vm.stepIsCurrent(step)
                          ? _c("stage-complete-icon")
                          : _vm._e(),
                        _vm.stepIsCurrent(step)
                          ? _c("stage-current-icon")
                          : _vm._e(),
                      ],
                      1
                    ),
                    !_vm.collapsed
                      ? _c("div", { staticClass: "mobile-font" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(step.name || step.heading) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ])
        : !_vm.stageLoaded
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-center" },
            [_c("ct-centered-spinner", { attrs: { variant: "primary" } })],
            1
          )
        : _vm._e(),
      _c("restart-stageline-confirmation-modal", {
        ref: "restart-stageline-confirmation-modal",
        attrs: { id: "restart-stageline-confirmation-modal" },
        on: { reset: _vm.startOverStageLine },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }