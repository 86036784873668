<template>
  <div id="document-step-component">
    <div class="step-name mb-4" style="font-size: 28px;">
      {{ step.heading }}
      <span>
        <fa-icon v-if="step.tool_tip" v-b-popover.hover.top="step.tool_tip" class="question-circle pl-1" icon="question-circle" size="xs" />
      </span>
    </div>
    <div class="step-description mb-2 justify-content-center">
      <div v-html="stepDescription" />
    </div>
    <v1-resource-side-by-side-viewer
      v-if="resource"
      ref="resource-side-by-side-viewer"
      :resource="resource"
      :company="company"
      @completed="documentComplete"
    />
    <pdf-download-modal id="pdf-download-modal" ref="pdf-download-modal" @submit="downloadModalSubmit" />
  </div>
</template>

<script>
import V1ResourceSideBySideViewer from '@/components/V1ResourceSideBySideViewer'
import PdfDownloadModal from '@/components/StagelineV1/modals/PdfDownloadModal'
import * as DOMPurify from 'dompurify'

export default {
  name: "DocumentStep",
  components: {
    PdfDownloadModal,
    V1ResourceSideBySideViewer,
  },
  props: {
    step: null,
    company: null,
  },
  computed: {
    resource() {
      return this.step.agency_resources[0]
    },
    stepDescription() {
      return DOMPurify.sanitize(this.step.description, {
        ALLOWED_TAGS: ['a', 'strong', 'em', 'p', 'br', 'div', 'span'],
        ALLOWED_ATTR: ['href', 'target'],
      })
    },
  },
  methods: {
    documentComplete() {
      this.$refs['pdf-download-modal'].show()
    },
    async downloadModalSubmit(wantToDownload) {
      if (wantToDownload) {
        await this.$refs['resource-side-by-side-viewer'].$refs['pdf-viewer'].download()
        this.$emit('proceed-to-next-step')
      } else {
        this.$emit('proceed-to-next-step')
      }
    },
  },
}
</script>

<style>

#document-step-component {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

</style>
