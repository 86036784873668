<template>
  <div>
    <div>
      <div class="font-28 step-name mb-4">
        {{ step.heading }}
        <span>
          <fa-icon v-if="step.tool_tip" v-b-popover.hover.top="step.tool_tip" class="question-circle pl-1" icon="question-circle" size="xs" />
        </span>
      </div>
      <div>
        <b-card v-for="(panel, index) in step.info_panels" :key="index" class="card">
          <b-card-text>
            <div class="parent-div">
              <div>
                <tool-tip class="tool-tip" />
              </div>
              <!-- eslint-disable vue/no-v-html -->
              <div v-html="sanitizeData(panel.panelData)" />
              <!--eslint-enable-->
            </div>
          </b-card-text>
        </b-card>
      </div>
      <br>
      <div class="step-description mb-2 justify-content-center">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="stepDescription" />
        <!--eslint-enable-->
      </div>
      <div v-if="step.url" class="resource-url mb-3">
        <p>
          <a :href="'//' + step.url" target="_blank">{{ step.url }}</a>
        </p>
      </div>
      <b-row v-if="step.image_urls.length" class="step-pdf-urls mb-3">
        <p v-for="url in step.image_urls" :key="url.id">
          Click <a :href="url" target="_blank">here</a> for additional information regarding this step.
        </p>
      </b-row>
      <schema-form
        ref="step-form"
        :key="renderKey"
        v-model="formValues"
        :fields="step.schema.fields"
        :suggestion-fields="suggestionFields"
        :contextual-jurisdiction="selectedJurisdiction"
        @ra-signup="raSignUp"
        @show-contact-modal="showContactModal"
        @suggestion-toggled="suggestionToggled($event)"
      />
      <b-list-group v-if="step.agency_resources" class="list-group">
        <b-list-group-item v-for="agencyResource in step.agency_resources" :key="agencyResource.id">
          <b-button variant="link" @click="openPDFViewer(agencyResource)">
            {{ agencyResource.name }}
          </b-button>
        </b-list-group-item>
      </b-list-group>
      <b-list-group v-if="availableFilingSteps.length" class="list-group">
        <b-list-group-item v-for="filingStep in availableFilingSteps" :key="filingStep.id">
          <b-button v-if="filingStep.resource.kind === 'url'" variant="link" @click="view(filingStep)">
            {{ filingStep.name }}
          </b-button>
          <b-button v-if="filingStep.resource.kind === 'document'" variant="link" @click="openFilingStepResource(filingStep.resource.id)">
            {{ filingStep.name }}
          </b-button>

          <b-modal :ref="filingStep.resource.id" size="xl" :title="filingStep.resource.name" :ok-only="true">
            <v1-resource-pdf-viewer :resource="filingStep.resource" :company-id="company.id" />
          </b-modal>
        </b-list-group-item>
      </b-list-group>
      <hr v-if="availableFilingSteps.length">
      <b-modal ref="agency-resource-modal" size="xl" :title="selectedAgencyResource ? selectedAgencyResource.name : ''" ok-only @close="clearSelection">
        <v1-resource-pdf-viewer v-if="selectedAgencyResource" :resource="selectedAgencyResource" :company-id="company.id" />
      </b-modal>
      <product-options-confirm-modal
        :bus="bus"
        :company="company"
        :loading="!loaded || loading"
        @completed="productOptionConfirmed"
      />
    </div>
  </div>
</template>

<script>
import SchemaForm from '@/components/SchemaForm/SchemaForm'
import axiosClient from '@/http'
import V1ResourcePdfViewer from '@/components/V1ResourcePDFViewer'
import ToolTip from '@images/ui/tool-tip.svg'
import { mapActions, mapGetters } from 'vuex'
import ProductOptionsConfirmModal from '@/components/StagelineV1/modals/ProductOptionsConfirmModal'
import Vue from 'vue'
import * as helper from '@/components/SchemaForm/helper.js'
import _ from 'lodash'
import * as DOMPurify from 'dompurify'

export default {
  name: 'BasicStep',
  components: {
    SchemaForm,
    V1ResourcePdfViewer,
    ToolTip,
    ProductOptionsConfirmModal,
  },
  props: {
    step: Object,
    company: Object,
    selectedJurisdiction: Object,
  },
  data() {
    return {
      loaded: false,
      loading: false,
      formValues: {},
      renderKey: 0,
      multipleKeys: [],
      suggestionFields: [],
      selectedAgencyResource: null,
      filingSteps: [],
      hireUsProducts: [],
      hireUsProduct: null,
      showProductOptionsModal: false,
      bus: new Vue(),
    }
  },
  computed: {
    ...mapGetters('checkout', [
      'allProducts', 'findCartItem',
    ]),
    ...mapGetters('companies', [
      'domesticRegistration',
      'checkoutDomesticJurisdiction',
    ]),
    availableFilingSteps() {
      if (this.filingSteps.length) {
        return this.filingSteps
          .filter(fs => this.filingStepFilterFunction(fs))
          .filter((fs, index, self) => {
            return index === self.findIndex(filingStep => filingStep.name === fs.name)
          })
      } else {
        return []
      }
    },
    stepDescription() {
      return DOMPurify.sanitize(this.step.description, {
        ALLOWED_TAGS: ['a'],
        ALLOWED_ATTR: ['href', 'target'],
      })
    },
  },
  watch: {
    step() {
      this.processStep() //TODO: is there a way to do this better for all of them - wanted to just unmount and mount every time but mutations are too quick or something
    },
  },
  mounted() {
    this.processStep()
  },
  methods: {
    ...mapActions('checkout', [
      'addToCart',
      'setupContext',
      'fetchProduct',
    ]),
    async processStep() {
      this.loaded = false
      this.multipleKeys = this.step.schema.fields.filter(f => 'can_have_multiple' in f && f['can_have_multiple'] === true).map(f => f.name)
      this.fillFormFromCompanyDetails()

      if (this.step.filing_names.length) {
        const response = await axiosClient.get(`client/companies/${this.company.id}/filing_step_resources_by_filing_names`, {
          params: {
            jurisdiction_id: this.selectedJurisdiction.id,
            filing_names: this.step.filing_names,
          },
        })

        this.filingSteps = response.data.result
      }

      this.form().values = this.formValues
      ++this.renderKey

      this.loaded = true
    },
    fillFormFromCompanyDetails () {
      const [formValues, suggestionFields] = helper.fillFormFromDetails(this.step.schema.fields, this.company.details)
      if (!_.isEmpty(formValues)) this.formValues = formValues
      if (Object.keys(suggestionFields).length)  this.suggestionFields = suggestionFields
    },
    async saveData() {

      const form = this.form()
      let details = {}

      if (!form.validate()) {
        return { valid: false, details: details }
      }

      this.formValues = helper.formatPeopleData(this.multipleKeys, this.formValues)
      helper.appendSuggestionValues(this.step.schema.fields, this.formValues)


      return { valid: true, details: this.formValues }
    },
    async utilityClicked() {
      if (!this.step.products.length > 0) {
        this.error()
        return
      }
      this.hireUsProducts = await this.fetchProduct({
        productKind: this.step.products[0].product_kind,
        productCategory: this.step.products[0].product_category,
        jurisdictionId: this.selectedJurisdiction.id,
      })

      if (!this.hireUsProducts.length) {
        this.error()
        return
      }
      this.bus.$emit('open-product-options-confirm', this.hireUsProducts)
    },
    productOptionConfirmed() {
      this.$emit('utility-complete')
    },
    async raSignUp() {
      let raProduct = await this.fetchProduct({
        productKind: 'registered_agent_product',
        jurisdictionId: this.selectedJurisdiction.id,
      })
      if (raProduct.length) {
        raProduct = raProduct[0]
      }

      await this.addToCart( { ...raProduct, skipResolveOptionalItems: true } )
    },
    suggestionToggled(event) {
      if (event.field.meta.type === 'address') {
        if (event.usingSuggestion === false) {
          delete this.suggestionFields[event.field.name]
        }
      }
    },
    showContactModal() {
      this.$emit('show-contact-modal')
    },
    form() {
      return this.$refs['step-form'] ? this.$refs['step-form'].form : {}
    },
    clearSelection() {
      this.selectedAgencyResource = null
    },
    openPDFViewer(agencyResource) {
      this.selectedAgencyResource = agencyResource
      this.$refs['agency-resource-modal'].show()
    },
    view(step) {
      window.open(step.resource.url)
    },
    openFilingStepResource(resourceId) {
      this.$refs[resourceId][0].show()
    },
    filingStepFilterFunction(filingStep) {
      if (filingStep.resource.locality === 'domestic') {
        return [this.domesticRegistration?.jurisdiction?.state_province_region,
          this.checkoutDomesticJurisdiction.state_province_region].includes(this.selectedJurisdiction.state_province_region)
      } else if (filingStep.resource.locality === 'foreign') {
        return ![this.domesticRegistration?.jurisdiction?.state_province_region,
          this.checkoutDomesticJurisdiction.state_province_region].includes(this.selectedJurisdiction.state_province_region)
      }

      return true
    },
    error() {
      this.$bvToast.toast('Unable to add product to cart at this time.', {
        title: 'Error',
        variant: 'danger',
        solid: true,
      })
      this.$emit('utility-complete')
    },
    sanitizeData(data) {
      return DOMPurify.sanitize(data, {
        ALLOWED_TAGS: ['a', 'strong', 'em', 'p', 'br', 'div', 'span', 'li'],
        ALLOWED_ATTR: ['href', 'target', 'rel'],
      })
    },
  },
}
</script>

<style lang="scss" scoped>

.question-circle {
  color: $blue;
}

.step-name {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  justify-content: space-evenly;
  margin-bottom: 16px;
  border-radius: $ct-ui-border-radius-3;
}

.parent-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.tool-tip {
  margin-right: 20px;
  width: 40px;
  height: 40px;
}

.font-28 {
  font-size: 1.75em !important;
}

svg {
  fill: #fff;
}

@media only screen and (max-width: 660px) {

}
</style>
