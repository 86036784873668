var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "restart-stageline-confirmation-modal",
      attrs: {
        id: "restart-stageline-confirmation-modal",
        title: "Warning:",
        "header-class": "no-borders",
        size: "md",
        "hide-footer": "",
      },
    },
    [
      _c("p", [
        _vm._v(
          "Resetting your Stageline progress will remove all completed steps/stages and you will restart from the beginning."
        ),
      ]),
      _c(
        "b-button",
        {
          attrs: { variant: "danger" },
          on: { click: _vm.resetStagelineProgress },
        },
        [_vm._v("\n    Reset\n  ")]
      ),
      _c(
        "b-button",
        {
          staticClass: "float-right",
          attrs: { variant: "primary" },
          on: { click: _vm.cancel },
        },
        [_vm._v("\n    Cancel\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }