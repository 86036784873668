var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "stageline" } }, [
    _c(
      "div",
      { attrs: { id: "stageline-sidebar-container" } },
      [_c("stageline-sidebar")],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "stageline-content-container w-100 d-flex flex-column align-items-center",
      },
      [
        _c("stageline-graphic", {
          attrs: {
            "stageline-type": this.$router.history.current.params.stagelineType,
          },
        }),
        _vm.loaded
          ? _c(
              "div",
              {
                staticClass: "w-100",
                class: { "wide-step": _vm.wideStep },
                attrs: { id: "stageline-container" },
              },
              [
                _c("stage-viewer", {
                  on: { "show-contact-modal": _vm.showContactModal },
                }),
                _vm.stagelineLoaded && !_vm.filteredStages.length
                  ? _c("div", { staticClass: "pt-3" }, [
                      _c("h3", { staticClass: "text-center" }, [
                        _vm._v(
                          "\n          Check back soon for step by step guidance for forming, managing and maintaining your company\n          in " +
                            _vm._s(_vm.jurisdiction.state_province_region) +
                            " on your personalized Stageline.\n        "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("contact-modal", {
                  ref: "contact-modal",
                  attrs: { title: "Add a contact", type: "add" },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }