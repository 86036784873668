var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded && _vm.step
    ? _c("div", { staticClass: "resting-step-container" }, [
        _c("h2", {
          staticClass: "step-heading pb-4 mt-0",
          domProps: { innerHTML: _vm._s(_vm.stepHeading) },
        }),
        _vm.step.image_urls.length && _vm.step.description
          ? _c("div", [
              _vm.step.layout_format === "image-left"
                ? _c("div", { class: "horz " + _vm.layoutFormat }, [
                    _c("div", { staticClass: "image" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.imageUrl,
                          alt: _vm.step.image_alt_text,
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "description" }, [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.stepDescription) },
                      }),
                    ]),
                  ])
                : _vm._e(),
              _vm.step.layout_format === "image-right"
                ? _c("div", { class: "horz " + _vm.layoutFormat }, [
                    _c("div", { staticClass: "description" }, [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.stepDescription) },
                      }),
                    ]),
                    _c("div", { staticClass: "image" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.imageUrl,
                          alt: _vm.step.image_alt_text,
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
              _vm.step.layout_format === "image-top"
                ? _c("div", { class: "vert " + _vm.layoutFormat }, [
                    _c("div", { staticClass: "image" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.imageUrl,
                          alt: _vm.step.image_alt_text,
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "description" }, [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.stepDescription) },
                      }),
                    ]),
                  ])
                : _vm._e(),
              _vm.step.layout_format === "image-bottom"
                ? _c("div", { class: "vert " + _vm.layoutFormat }, [
                    _c("div", { staticClass: "description" }, [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.stepDescription) },
                      }),
                    ]),
                    _c("div", { staticClass: "image" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.imageUrl,
                          alt: _vm.step.image_alt_text,
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm.step.description
          ? _c("div", [
              _c("div", { staticClass: "description" }, [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.stepDescription) },
                }),
              ]),
            ])
          : _vm.step.image_urls.length
          ? _c("div", [
              _c("div", { staticClass: "image-vert" }, [
                _c("img", {
                  attrs: { src: _vm.imageUrl, alt: _vm.step.image_alt_text },
                }),
              ]),
            ])
          : _vm._e(),
      ])
    : _c("div", [
        _c(
          "div",
          { staticClass: "d-flex justify-content-center mt-2 mb-2" },
          [_c("b-spinner", { attrs: { variant: "primary", large: "" } })],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }