var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "container-fluid", attrs: { id: "checkout-step" } },
    [
      _vm.loaded
        ? _c(
            "b-row",
            { staticClass: "checkout-step-flex" },
            [
              !_vm.emptyCartAndCheckoutView
                ? _c(
                    "b-col",
                    { attrs: { md: _vm.calculateColumns, cols: "12" } },
                    [_c("router-view")],
                    1
                  )
                : _vm._e(),
              _vm.$router.currentRoute.meta.showCart &&
              !_vm.emptyCartAndCheckoutView
                ? _c(
                    "b-col",
                    { staticClass: "mt-5", attrs: { cols: "12", md: 5 } },
                    [
                      _c("cart", {
                        attrs: {
                          "hide-button": _vm.cartEmpty,
                          "processing-checkout": _vm.processingCheckout,
                          "button-text": "Checkout",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.emptyCartAndCheckoutView
                ? _c("h5", { staticClass: "w-100 text-center" }, [
                    _vm._v(
                      "\n      There are no products currently available for checkout.\n    "
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "mt-3 mb-0 d-flex flex-row justify-content-end w-100",
                },
                [
                  _vm.showContinueButton
                    ? _c(
                        "b-button",
                        {
                          staticClass: "next-button",
                          attrs: { variant: "primary", disabled: false },
                          on: { click: _vm.nextPage },
                        },
                        [
                          _c("span", { staticStyle: { padding: "0 .5rem" } }, [
                            _vm._v("\n          Continue\n        "),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _c("div", [
            _c(
              "div",
              { staticClass: "d-flex justify-content-center mt-2 mb-2" },
              [_c("b-spinner", { attrs: { variant: "primary", large: "" } })],
              1
            ),
          ]),
      _c("collect-account-info", {
        ref: `collectAccountInfoModal-${_vm.thisUid}`,
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }