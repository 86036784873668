var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded && !_vm.selectedProductConfirmed
    ? _c(
        "b-container",
        { staticClass: "container-fluid", attrs: { id: "product-step" } },
        [
          _c("h3", [_vm._v(_vm._s(_vm.currentStep.heading))]),
          _c("br"),
          _c(
            "div",
            { staticClass: "step-description mb-2 justify-content-center" },
            [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.currentStepDescription) },
              }),
            ]
          ),
          _vm.loaded && _vm.stepProductsToUse
            ? _c(
                "b-row",
                [
                  _c(
                    "b-card-group",
                    {
                      class: { horizontal: _vm.horizontal },
                      attrs: { deck: "" },
                    },
                    _vm._l(_vm.stepProductsToUse, function (sp) {
                      return _c("product-card", {
                        key: sp.id,
                        attrs: {
                          "step-product": sp,
                          disabled: _vm.isDisabled(sp),
                          "step-jurisdiction": _vm.jurisdiction,
                        },
                        on: {
                          chosen: function ($event) {
                            return _vm.chooseProductHandler(sp)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _c("ct-centered-spinner"),
          _c("product-options-confirm-modal", {
            attrs: {
              bus: _vm.bus,
              loading: !_vm.loaded || _vm.loading,
              company: _vm.company,
            },
            on: { complete: _vm.productOptionConfirmed },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }