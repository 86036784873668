<template>
  <b-card
    v-if="loaded && valid"
    :title="stepProduct.title"
    :class="{ 'disabled': disabled }"
    class="product-card-component"
    footer-bg-variant="transparent"
    @click="choose"
  >
    <b-card-text>
      {{ productDescription }}
    </b-card-text>
    <template v-if="showFooter" #footer>
      <b-card-text v-if="!isFreeOrNullPrice">
        <div>
          <i>{{ priceDescription }}</i>
        </div>
        <div>
          <b>${{ purchaseInfo }}</b>
        </div>
      </b-card-text>

      <b-button variant="primary" class="borderless">
        <b>
          {{ stepProduct.button_text }}
        </b>
      </b-button>
    </template>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'

export default {
  name: "ProductCard",
  props: {
    stepProduct: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    stepJurisdiction: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      price: 0,
      products: [],
      loaded: false,
    }
  },
  computed: {
    ...mapGetters('stageline', ['jurisdiction']),
    ...mapGetters('checkout', ['registeredAgentProductByJurisdiction']),
    ...mapGetters('companies', ['hasExistingRAServiceInJurisdiction', 'currentCompany']),

    showFooter() {
      return this.price !== null || this.isFreeOrNullPrice
    },
    isFreeOrNullPrice(){
      return this.stepProduct.product_kind === 'diy' && (this.stepProduct.config.price == 0 || this.stepProduct.config.price === null)
    },
    isSubscription() {
      return this.stepProduct.product !== undefined
        && this.stepProduct.product_kind === 'subscription_bundle'
    },
    productDescription() {
      let productDescription = this.stepProduct.description
      if (this.isSubscription) {
        productDescription = productDescription.replaceAll('::price-per-month::', this.price)
      }
      return productDescription
    },
    priceDescription() {
      if (this.isSubscription) {
        return 'Pricing starts at'
      }
      return 'One Time Price'
    },
    purchaseInfo() {
      if (this.isSubscription) {
        return `${this.price} / month`
      }
      return `${this.price} Total`
    },
    valid() {
      return this.stepProduct.product !== undefined || this.stepProduct.config.price !== undefined
    },
  },
  async mounted() {
    this.loaded = false

    const products = await this.fetchProduct({
      productKind: this.stepProduct.product_kind,
      productCategory: this.stepProduct.product_category,
      jurisdictionId: this.jurisdiction.id,
    })

    this.stepProduct.product = products.shift()

    if (this.valid) {
      if (this.isSubscription) {
        this.price = this.stepProduct.product.monthly_amount
      } else if (this.stepProduct.product?.name === 'Free Form a Company') {
        this.price = this.lowestFilingMethod() + this.freeFilingProcessingFee(this.stepProduct.product)
      } else if (this.stepProduct.config.price || this.isFreeOrNullPrice) {
        this.price = this.stepProduct.config.price
      } else if (this.productIsEligibleForCostOverride()) {
        this.price = this.productOverridePriceForStepJurisdiction() +
                     this.lowestFilingMethod() +
                     this.raCost()
      } else {
        this.price = this.stepProduct.product.price +
                     this.lowestFilingMethod() +
                     this.raCost()
      }
      this.price = this.price % 1 ?  this.price.toFixed(2) : this.price
    }
    this.loaded = true
  },
  methods: {
    ...mapActions('checkout', ['fetchProduct']),
    choose() {
      if (this.disabled) {
        return
      }

      this.$emit('chosen')
    },
    freeFilingProcessingFee(product) {
      const sort = (a, b) => { return b.cost - a.cost }
      const filingMethod = product.filing_methods.sort(sort)[0]

      return Math.round(filingMethod.cost * 0.05)
    },
    lowestFilingMethod() {
      if (!this.stepProduct.product.filing_methods || this.stepProduct.product.filing_methods.length === 0) {
        return 0
      }
      return _.min(this.stepProduct.product.filing_methods.map(fm => fm.cost))
    },
    raCost() {
      let raCost = 0
      if (!this.hasExistingRAServiceInJurisdiction(this.stepJurisdiction) &&
        (this.stepProduct.product.filing_name === 'form a company' || this.stepProduct.product.filing_name === 'register a company')) {
        raCost = this.registeredAgentProductByJurisdiction(this.stepJurisdiction.state_province_region).price
      }

      return raCost
    },
    productIsEligibleForCostOverride() {
      if (!this.stepProduct.product){
        return false
      }

      const isEligibleForOverride = !this.stepProduct.config.price
        && !this.isFreeOrNullPrice
        && (!_.isEmpty(this.stepProduct.product.jurisdiction_price_overrides)
        || !_.isEmpty(this.stepProduct.product.local_jurisdiction_price_overrides))

      return isEligibleForOverride
    },
    highestFilingMethod() {
      if (!this.stepProduct?.product?.filing_methods || this.stepProduct.product.filing_methods.length === 0) {
        return ''
      }
      return this.stepProduct.product.filing_methods.reduce((prev, current) => (prev.cost > current.cost) ? prev : current)
    },
    productOverridePriceForStepJurisdiction() {
      const ljpo = this.stepProduct.product.local_jurisdiction_price_overrides
      const jpo = this.stepProduct.product.jurisdiction_price_overrides
      let overridePriceForJurisdiction = 0

      if (Object.keys(ljpo).length) {
        let localJurisdictionPrice = []
        const highestFilingMethod = this.highestFilingMethod()

        if (highestFilingMethod && highestFilingMethod.local_jurisdiction_id in ljpo) {
          localJurisdictionPrice.push(ljpo[highestFilingMethod.local_jurisdiction_id])
        }

        overridePriceForJurisdiction += localJurisdictionPrice.length ?
          _.max(localJurisdictionPrice) :
          this.stepProduct.product.price
      } else if (this.stepJurisdiction.id in jpo) {
        overridePriceForJurisdiction += jpo[this.stepJurisdiction.id]
      } else {
        overridePriceForJurisdiction += this.stepProduct.product.price
      }

      return overridePriceForJurisdiction
    },
  },
}
</script>

<style lang="scss" scoped>

  .product-card-component {
    @include ct-ui-selectable;
    &.disabled {
      cursor: default;
    }
  }
  .card {
    @include ct-ui-card-shadow;

    border-radius: 10px;
    margin-bottom: 15px;
    min-height: 400px;
    min-width: 280px;
    max-width: 300px;
    text-align: center;
  }
  .card-title {
    margin-top: 10px;
    margin-bottom: 18px;
  }
  .card-text {
    font-size: 14px;
    display: flex;
      flex-flow: column;
      i {
        font-weight: 900;
      }
      b {
        font-size: 16px;
        color: $ct-ui-color-5;
      }
  }
  .btn {
    padding: 12px;
    margin-top: 5px;
    font-size: 14px;
    width: 100%;
    //background: $ct-ui-color-3;
  }
  .card-footer {
    border: none;
  }

</style>
