var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "document-step-component" } },
    [
      _c(
        "div",
        { staticClass: "step-name mb-4", staticStyle: { "font-size": "28px" } },
        [
          _vm._v("\n    " + _vm._s(_vm.step.heading) + "\n    "),
          _c(
            "span",
            [
              _vm.step.tool_tip
                ? _c("fa-icon", {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top",
                        value: _vm.step.tool_tip,
                        expression: "step.tool_tip",
                        modifiers: { hover: true, top: true },
                      },
                    ],
                    staticClass: "question-circle pl-1",
                    attrs: { icon: "question-circle", size: "xs" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "step-description mb-2 justify-content-center" },
        [_c("div", { domProps: { innerHTML: _vm._s(_vm.stepDescription) } })]
      ),
      _vm.resource
        ? _c("v1-resource-side-by-side-viewer", {
            ref: "resource-side-by-side-viewer",
            attrs: { resource: _vm.resource, company: _vm.company },
            on: { completed: _vm.documentComplete },
          })
        : _vm._e(),
      _c("pdf-download-modal", {
        ref: "pdf-download-modal",
        attrs: { id: "pdf-download-modal" },
        on: { submit: _vm.downloadModalSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }