'<template>
  <b-container id="checkout-step" class="container-fluid">
    <b-row v-if="loaded" class="checkout-step-flex">
      <b-col v-if="!emptyCartAndCheckoutView" :md="calculateColumns" cols="12">
        <router-view />
      </b-col>
      <b-col v-if="$router.currentRoute.meta.showCart && !emptyCartAndCheckoutView"
             class="mt-5"
             cols="12"
             :md="5"
      >
        <cart
          :hide-button="cartEmpty"
          :processing-checkout="processingCheckout"
          button-text="Checkout"
        />
      </b-col>
      <h5 v-if="emptyCartAndCheckoutView" class="w-100 text-center">
        There are no products currently available for checkout.
      </h5>
      <div class="mt-3 mb-0 d-flex flex-row justify-content-end w-100">
        <b-button v-if="showContinueButton"
                  class="next-button"
                  variant="primary"
                  :disabled="false"
                  @click="nextPage"
        >
          <span style="padding: 0 .5rem">
            Continue
          </span>
        </b-button>
      </div>
    </b-row>
    <div v-else>
      <div class="d-flex justify-content-center mt-2 mb-2">
        <b-spinner variant="primary" large />
      </div>
    </div>
    <collect-account-info :ref="`collectAccountInfoModal-${thisUid}`" />
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Cart from "@/components/HireUs/Cart"
import CollectAccountInfo from "@/components/CollectAccountInfo"

export default {
  name: 'CheckoutStep',

  components: { Cart, CollectAccountInfo },

  props: {},

  data() {
    return {
      productKind: null,
      visible: false,
      processingCheckout: false,
      result: null,
      thisUid: this._uid,
    }
  },
  computed: {
    ...mapGetters('account', ['missingContactInformation']),
    ...mapGetters('checkout', ['allProducts', 'cartItems', 'cartEmpty']),
    ...mapGetters('stageline', ['company']),
    loaded() {
      return true
    },
    routeParams() {
      return this.$router.currentRoute.params
    },
    invoiceHasItemsRequiringAttention() {
      //TODO: Need to add subscription here....
      return (this.result.order && this.result.order.order_items.some(oi => oi.status === 'awaiting-client-input'))
        || (this.result.services && this.result.services.some(s => s.status === 'awaiting-client-input'))
    },
    showContinueButton() {
      switch (this.$route.name) {
        case 'stageline-checkout-payment': {
          if (this.cartEmpty) {
            return true
          }
          break
        }
        case 'stageline-post-payment': {
          return true
        }
      }
      return false
    },
    emptyCartAndCheckoutView() {
      return this.$route.name === 'stageline-checkout-payment'
        && this.cartEmpty
        && !this.processingCheckout
    },
    buttonText() {
      if (this.missingContactInformation) {
        return 'Confirm Info'
      }
      return 'Purchase'
    },
    calculateColumns() {
      return this.$router.currentRoute.meta.showCart ? 7 : 12
    },
  },
  async mounted() {
    await this.$router.push({
      name: 'stageline-checkout-payment',
    })
    await this.loadCartItems()

    this.$root.$on('cart-next-page', () => {
      this.nextPage()
    })
  },
  destroyed() {
    this.$root.$off('cart-next-page')
  },
  // Do category and then do product id
  methods: {
    ...mapActions('checkout', [
      'loadProducts',
      'addToCart',
      'clearCart',
      'checkout',
      'loadCartItems',
    ]),
    ...mapActions('companies', [
      'loadActiveItems',
    ]),
    routeIs(routeName) {
      return this.$route.name === routeName
    },
    complete() {
      this.$emit('proceed-to-next-step')
    },
    async nextPage() {
      switch (this.$route.name) {
        case 'stageline-checkout-payment': {
          if (!this.cartEmpty) {
            if (this.missingContactInformation) {
              const ref = `collectAccountInfoModal-${this.thisUid}`
              this.$refs[ref].show()
            } else {
              this.processingCheckout = true
              this.result = await this.checkout(this)
              if (this.result.invoice) {
                // TODO Might tranche this out to set state at more granularity, just trying to get
                // something working for now. Really we should set it in the checkout store where we add
                // any service, order items, or subscriptions to the company store active *
                await this.loadActiveItems({ id: this.company.id })

                if (this.invoiceHasItemsRequiringAttention) {
                  await this.routeToPostPayment()
                }
              }
            }
          } else {
            this.complete()
          }
          this.processingCheckout = false
          break
        }
        case 'stageline-post-payment': {
          this.routeToItemsRequiringAttention()
          break
        }
        case 'stageline-items-requiring-attention': {
          this.complete()
          break
        }
        default: {
          this.complete()
        }
      }
    },
    routeToOptionalItems() {
      this.$router.push({ name: 'stageline-optional-items',
        params: this.routeParams,
      })
    },
    routeToPayment(){
      this.$router.push({
        name: 'stageline-checkout-payment',
      })
    },
    async routeToPostPayment() {
      await this.$router.push({ name: 'stageline-post-payment' })
    },
    routeToItemsRequiringAttention() {
      if (this.result.invoice) {
        this.$router.push({
          name: 'stageline-required-info',
          params: {
            invoiceId: this.result.invoice.id,
          },
        })
      } else {
        this.complete()
      }
    },
  },
}
</script>
<style scoped lang="scss">
@media only screen and (max-width: 660px) {
  #checkout-step {
    padding: 0 !important;

    .checkout-step-flex {
      width: 100% !important;
      margin: 0 !important;

      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
