var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c("div", { staticClass: "font-28 step-name mb-4" }, [
          _vm._v("\n      " + _vm._s(_vm.step.heading) + "\n      "),
          _c(
            "span",
            [
              _vm.step.tool_tip
                ? _c("fa-icon", {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top",
                        value: _vm.step.tool_tip,
                        expression: "step.tool_tip",
                        modifiers: { hover: true, top: true },
                      },
                    ],
                    staticClass: "question-circle pl-1",
                    attrs: { icon: "question-circle", size: "xs" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c(
          "div",
          _vm._l(_vm.step.info_panels, function (panel, index) {
            return _c(
              "b-card",
              { key: index, staticClass: "card" },
              [
                _c("b-card-text", [
                  _c("div", { staticClass: "parent-div" }, [
                    _c("div", [_c("tool-tip", { staticClass: "tool-tip" })], 1),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(_vm.sanitizeData(panel.panelData)),
                      },
                    }),
                  ]),
                ]),
              ],
              1
            )
          }),
          1
        ),
        _c("br"),
        _c(
          "div",
          { staticClass: "step-description mb-2 justify-content-center" },
          [_c("div", { domProps: { innerHTML: _vm._s(_vm.stepDescription) } })]
        ),
        _vm.step.url
          ? _c("div", { staticClass: "resource-url mb-3" }, [
              _c("p", [
                _c(
                  "a",
                  { attrs: { href: "//" + _vm.step.url, target: "_blank" } },
                  [_vm._v(_vm._s(_vm.step.url))]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.step.image_urls.length
          ? _c(
              "b-row",
              { staticClass: "step-pdf-urls mb-3" },
              _vm._l(_vm.step.image_urls, function (url) {
                return _c("p", { key: url.id }, [
                  _vm._v("\n        Click "),
                  _c("a", { attrs: { href: url, target: "_blank" } }, [
                    _vm._v("here"),
                  ]),
                  _vm._v(
                    " for additional information regarding this step.\n      "
                  ),
                ])
              }),
              0
            )
          : _vm._e(),
        _c("schema-form", {
          key: _vm.renderKey,
          ref: "step-form",
          attrs: {
            fields: _vm.step.schema.fields,
            "suggestion-fields": _vm.suggestionFields,
            "contextual-jurisdiction": _vm.selectedJurisdiction,
          },
          on: {
            "ra-signup": _vm.raSignUp,
            "show-contact-modal": _vm.showContactModal,
            "suggestion-toggled": function ($event) {
              return _vm.suggestionToggled($event)
            },
          },
          model: {
            value: _vm.formValues,
            callback: function ($$v) {
              _vm.formValues = $$v
            },
            expression: "formValues",
          },
        }),
        _vm.step.agency_resources
          ? _c(
              "b-list-group",
              { staticClass: "list-group" },
              _vm._l(_vm.step.agency_resources, function (agencyResource) {
                return _c(
                  "b-list-group-item",
                  { key: agencyResource.id },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "link" },
                        on: {
                          click: function ($event) {
                            return _vm.openPDFViewer(agencyResource)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(agencyResource.name) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              1
            )
          : _vm._e(),
        _vm.availableFilingSteps.length
          ? _c(
              "b-list-group",
              { staticClass: "list-group" },
              _vm._l(_vm.availableFilingSteps, function (filingStep) {
                return _c(
                  "b-list-group-item",
                  { key: filingStep.id },
                  [
                    filingStep.resource.kind === "url"
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.view(filingStep)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(filingStep.name) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    filingStep.resource.kind === "document"
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.openFilingStepResource(
                                  filingStep.resource.id
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(filingStep.name) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "b-modal",
                      {
                        ref: filingStep.resource.id,
                        refInFor: true,
                        attrs: {
                          size: "xl",
                          title: filingStep.resource.name,
                          "ok-only": true,
                        },
                      },
                      [
                        _c("v1-resource-pdf-viewer", {
                          attrs: {
                            resource: filingStep.resource,
                            "company-id": _vm.company.id,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            )
          : _vm._e(),
        _vm.availableFilingSteps.length ? _c("hr") : _vm._e(),
        _c(
          "b-modal",
          {
            ref: "agency-resource-modal",
            attrs: {
              size: "xl",
              title: _vm.selectedAgencyResource
                ? _vm.selectedAgencyResource.name
                : "",
              "ok-only": "",
            },
            on: { close: _vm.clearSelection },
          },
          [
            _vm.selectedAgencyResource
              ? _c("v1-resource-pdf-viewer", {
                  attrs: {
                    resource: _vm.selectedAgencyResource,
                    "company-id": _vm.company.id,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("product-options-confirm-modal", {
          attrs: {
            bus: _vm.bus,
            company: _vm.company,
            loading: !_vm.loaded || _vm.loading,
          },
          on: { completed: _vm.productOptionConfirmed },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }