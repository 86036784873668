var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded && _vm.valid
    ? _c(
        "b-card",
        {
          staticClass: "product-card-component",
          class: { disabled: _vm.disabled },
          attrs: {
            title: _vm.stepProduct.title,
            "footer-bg-variant": "transparent",
          },
          on: { click: _vm.choose },
          scopedSlots: _vm._u(
            [
              _vm.showFooter
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        !_vm.isFreeOrNullPrice
                          ? _c("b-card-text", [
                              _c("div", [
                                _c("i", [_vm._v(_vm._s(_vm.priceDescription))]),
                              ]),
                              _c("div", [
                                _c("b", [
                                  _vm._v("$" + _vm._s(_vm.purchaseInfo)),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _c(
                          "b-button",
                          {
                            staticClass: "borderless",
                            attrs: { variant: "primary" },
                          },
                          [
                            _c("b", [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.stepProduct.button_text) +
                                  "\n      "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("b-card-text", [
            _vm._v("\n    " + _vm._s(_vm.productDescription) + "\n  "),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }