var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "stageline-sidebar", attrs: { id: "stageline-sidebar-component" } },
    [
      _c(
        "div",
        {
          attrs: { id: "stageline-sidebar-toggle" },
          on: { click: _vm.toggleSidebar },
        },
        [
          _c("double-arrow", {
            class: { collapsed: _vm.collapsed },
            attrs: { id: "stageline-sidebar-double-arrow" },
          }),
        ],
        1
      ),
      _c("stepline", { attrs: { collapsed: _vm.collapsed } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }