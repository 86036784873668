<template>
  <div id="step-viewer-component">
    <template v-if="stepLoaded">
      <resting-step
        v-if="stepLayoutType === 'resting'"
        ref="resting-step"
        :step="currentStep"
        v-on="$listeners"
      />
      <checkout-step
        v-else-if="stepLayoutType === 'checkout'"
        ref="checkout-step"
        v-on="$listeners"
      />
      <product-step
        v-else-if="stepLayoutType === 'product'"
        ref="product-step"
        :step-products="currentStep.products"
        v-on="$listeners"
      />
      <document-step
        v-else-if="stepLayoutType === 'document'"
        ref="document-step"
        :key="currentStep.id"
        :step="currentStep"
        :company="company"
        @document-complete="documentComplete"
        v-on="$listeners"
      />
      <basic-step
        v-else
        ref="basic-step"
        :step="currentStep"
        :company="company"
        :selected-jurisdiction="jurisdiction"
        @utility-complete="handleUtilityComplete"
        v-on="$listeners"
      />
    </template>
    <div v-else-if="!stepLoaded" class="d-flex flex-row justify-content-center">
      <ct-centered-spinner label="Loading..." />
    </div>
    <div v-show="showSlideControls" class="mt-3 mb-0 step-viewer-button-container">
      <span>
        <b-button v-if="showUtilityButton"
                  class="btn utility-button btn-preferred"
                  :disabled="utilityButtonDisabled"
                  @click="utilityPressed"
        >
          {{ utilityButtonText }}
        </b-button>
      </span>
      <span class="step-viewer-button-column">
        <b-button v-if="showBackButton && !currentStep.config.back_button.hide"
                  variant="default"
                  @click="back"
        >
          <fa-icon icon="chevron-left" class="fa-xs" /> {{ currentStep.config.back_button.text || 'Previous' }}
        </b-button>
        <b-button v-if="showContinueButton && !currentStep.config.continue_button.hide"
                  class="next-button"
                  :disabled="!stepLoaded"
                  variant="primary"
                  @click="next"
        >
          <span v-if="stepLoaded" style="padding: 0 .5rem">
            {{ continueButtonText }}
          </span>
          <b-spinner v-else small variant="light" />
        </b-button>
      </span>
    </div>
    <div v-if="errorMessage" class="errorMessage">
      {{ errorMessage }}
    </div>
    <br>
  </div>
</template>

<script>
import BasicStep from './steps/BasicStep'
import CheckoutStep from './steps/checkout/CheckoutStep'
import ProductStep from './steps/product/ProductStep'
import DocumentStep from './steps/document/DocumentStep'
import RestingStep from './steps/resting/RestingStep'
import { mapActions, mapGetters } from 'vuex'
import CtCenteredSpinner from '../shared/CtCenteredSpinner'
import { SERVICE_TYPES_MULTI_PURCHASABLE } from '@/common/modules/constants'

// Responsibilities and Notes:
// 1. Manage specific Step
// 2. Manage saving data or any interaction at next step level

export default {
  name: 'StepViewer',
  components: {
    BasicStep,
    CheckoutStep,
    ProductStep,
    DocumentStep,
    RestingStep,
    CtCenteredSpinner,
  },
  props: {
    showBackButton: {
      type: Boolean,
      default: true,
    },
    showContinueButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      errorMessage: null,
      loaded: false,
      utilityButtonWasClicked: false,
      utilityComplete: false,
    }
  },
  computed: {
    ...mapGetters('checkout', [
      'allProducts',
      'findCartItem',
    ]),
    ...mapGetters('stageline', [
      'currentStep',
      'stepLoaded',
      'company',
      'jurisdiction',
      'cartItemExistsByCategory',
    ]),
    ...mapGetters('companies', [
      'hasProductOfTypeWithCompanyOrInCart',
    ]),
    // TODO someday, remove the dumb getter "utilityButtonWasClicked" and make things a little
    // TODO smarter, but it's been approved for MVP release.
    continueButtonText() {
      if (this.utilityButtonWasClicked || this.resetContinueButtonText) {
        return 'Continue'
      } else {
        return this.currentStep?.config.continue_button.text || 'Continue'
      }
    },
    stepLayoutType() {
      return this.currentStep ? this.currentStep.layout_type : 'basic'
    },
    basicUtilityButtonShow() {
      return this.currentStep?.layout_type === 'basic' && this.currentStep?.products.length === 1
    },
    showUtilityButton() {
      return this.basicUtilityButtonShow
    },
    utilityButtonText() {
      if (this.basicUtilityButtonShow) {
        switch (this.currentStep.products[0].product_kind) {
          case 'registered_agent_product':
          case 'subscription_bundle':
            return 'Add this service to your cart'
          case 'filing_product':
            return 'Add this filing to your cart'
          default:
            return 'Add this product to your cart'
        }
      }
      else return ''
    },
    utilityButtonDisabled() {
      const self = this
      return this.currentStep.products.some(product => {
        if (Object.values(SERVICE_TYPES_MULTI_PURCHASABLE).includes(product.product_category)) {
          return false
        } else {
          return self.hasProductOfTypeWithCompanyOrInCart(product.product_category, self.jurisdiction)
        }
      })
    },
    resetContinueButtonText() {
      const self = this
      return this.currentStep.products.some(function(product) {
        switch (product.product_kind) {
          case 'registered_agent_product':
            product.product_category = 'registered-agent'
            break
          case 'subscription_bundle':
            product.product_category = 'subscription-bundle'
        }
        return self.cartItemExistsByCategory(product.product_category)
      })
    },
    currentStepRef() {
      return this.currentStep.layout_type + '-step'
    },
    showSlideControls() {
      return this.showUtilityButton ||
             this.showBackButton && !this.currentStep.config.back_button.hide ||
             this.showContinueButton && !this.currentStep.config.continue_button.hide
    },
  },
  methods: {
    ...mapActions('stageline', [
      'updateStage',
    ]),
    reset() {
      this.errorMessage = null
      this.utilityButtonWasClicked = false
      this.utilityComplete = false
      this.loaded = false
    },
    back() {
      this.reset()
      this.$emit('back')
    },
    next() {
      this.reset()
      this.$emit('continue')
    },
    utilityPressed() {
      if (this.basicUtilityButtonShow && this.$refs[this.currentStepRef].utilityClicked) {
        this.$refs[this.currentStepRef].utilityClicked()
      }
      this.utilityButtonWasClicked = true
    },
    documentComplete() {
      // TODO: what do actually want to do when the client finishes the document? Prompt for download I assume?
    },
    handleUtilityComplete() {
      this.utilityComplete = true
    },
    async saveStepData() {
      let result = { valid: true, details: {} }
      this.errorMessage = null

      if (this.stepLayoutType === 'basic') {
        result = await this.$refs['basic-step'].saveData()
      }

      if (result.valid) {
        try {
          await this.updateStage({ details: result['details'] })

          return true
        } catch (error) {
          this.$bvToast.toast('An error has occurred when attempting to update your Stageline progress, please try again.', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })

          return false
        }
      } else {
        this.errorMessage = 'Please complete required items'
      }
      return result.valid
    },
  },
}
</script>

<style lang="scss" scoped>
#step-viewer-component {
  width: 100%;
  /* TODO for debugging */
  /*background: #17a2b8;*/
  margin: 0 auto;
  & > div:first-of-type {
    min-height: 240px;
  }

  .step-viewer-button-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .question-circle {
    color: $blue;
  }

  .resource-name {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .step-title {
    text-align: left;
  }

  ::v-deep .form-control,
  ::v-deep select {
    height: 60px !important;
  }
  ::v-deep [role=group] .form-control {
    margin-bottom: 10px;
  }

  .next-button {
    width: auto;
    min-width: 130px;
    padding: .6rem 0;
  }
  .utility-button {
    font-weight: bolder;
    min-width: 130px;
  }
  .errorMessage {
    text-align: right;
    color: red;
  }
}

@media only screen and (max-width: 1024px) {
  #step-viewer-component {

    .step-viewer-button-container {
      width: 100% !important;
      flex-flow: row nowrap;
      row-gap: 2.0em;
      padding-top: 2.0em;
      margin: 0;

      .step-viewer-button-column {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        column-gap: 2.0em;
      }
    }
  }
}

@media only screen and (max-width: 660px) {
  #step-viewer-component {

    .step-viewer-button-container {
      width: 100% !important;
      flex-flow: column nowrap;
      row-gap: 2.0em;
      padding-top: 2.0em;
      margin: 0;

      .step-viewer-button-column {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        column-gap: 2.0em;
      }
    }
  }
}
</style>
