<template>
  <b-modal id="pdf-download-modal" ref="pdf-download-modal" hide-footer>
    <b-form-group>
      <span>You have completed filling out the necessary information for this document! Now you can download the form for submission: </span>
      <hr>
      <div>
        <b-button variant="danger" @click="submit(true)">
          Skip Download
        </b-button>
        <b-button variant="primary" class="float-right" @click="submit(true)">
          Download
        </b-button>
      </div>
    </b-form-group>
  </b-modal>
</template>

<script>
export default {
  name: "PdfDownloadModal",
  methods: {
    show() {
      this.$refs['pdf-download-modal'].show()
    },
    submit(decision) {
      this.$emit('submit', decision)
      this.$refs['formation-info-modal'].hide()
    },
  },
}
</script>

<style scoped>

</style>
