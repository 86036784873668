<template>
  <div v-if="currentStage && stageLoaded" id="stage-viewer-component">
    <step-viewer v-if="!stageHasNoFilteredSteps"
                 ref="step-viewer"
                 :show-back-button="showBackButton"
                 :show-continue-button="showContinueButton"
                 @back="back"
                 @continue="next"
                 @show-contact-modal="showContactModal"
                 @proceed-to-next-step="next"
    />

    <div v-if="stageHasNoFilteredSteps" id="no-steps-available">
      <h1 style="margin: 0 0 10px 0">
        There's nothing left to do on this stage.
      </h1>
      <p style="margin-bottom: 40px;">
        Looks like everything on this stage has been taken care of.
        Click to continue your company stageline.
      </p>
      <div class="image-container">
        <picture>
          <source
            :srcset="require('@images/illustrations/stageline/stageline-continue-sign.webp')"
            type="image/webp"
          >
          <img
            :src="require('@images/illustrations/stageline/stageline-continue-sign.png')"
            alt="Continue"
            class="image"
          >
        </picture>
      </div>
      <b-button class="next-button"
                variant="primary"
                @click="next"
      >
        <span style="padding: 0 .5rem">
          Continue
        </span>
      </b-button>
    </div>
  </div>
  <div v-else-if="!stageLoaded" class="d-flex flex-row justify-content-center">
    <ct-centered-spinner label="Loading..." />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import StepViewer from './StepViewer'
import CtCenteredSpinner from '../shared/CtCenteredSpinner'

// Responsibilities and Notes:
// 1. Load the step viewer
// 2. Manage navigation and transitions for stages and steps

export default {
  name: "StageViewer",
  components: {
    StepViewer,
    CtCenteredSpinner,
  },
  props: {
    stage: Object,
    availableStages: Array,
  },
  data() {
    return {
      filterProducts: [],
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'currentStage',
      'company',
      'currentStageIndex',
      'currentStepIndex',
      'filteredStages',
      'filteredSteps',
      'stageLoaded',
      'stepLoaded',
      'stagelineType',
      'stagelineComplete',
      'stepIsLast',
      'stageIsLast',
    ]),
    ...mapGetters('companies', ['formedElsewhere']),
    showBackButton() {
      return (this.currentStageIndex > 0) || (this.currentStepIndex > 0)
    },
    showContinueButton() {
      return (this.currentStageIndex <= this.filteredStages.length) || (this.currentStepIndex < this.filteredSteps.length - 1)
    },
    stageHasNoFilteredSteps() {
      return (this.filteredSteps.length === 0)
    },
  },
  methods: {
    ...mapActions('stageline', [
      'setCurrentStage',
      'setCurrentStep',
      'setStepLoaded',
    ]),

    filterFilingProducts(product, item){
      if (!product.name && product.name.toUpperCase() === item.kind.toUpperCase()){
        return product
      } else if (!product.category && product.category.toUpperCase() === item.kind.toUpperCase()){
        return product
      } else if (!product.filing_name && product.filing_name.toUpperCase() === item.kind.toUpperCase()){
        return product
      }
    },
    async back() {
      this.setStepLoaded(false)
      if (this.currentStepIndex === 0) {
        if (this.currentStageIndex > 0) {
          await this.setCurrentStage(this.currentStageIndex - 1)
          await this.setCurrentStep(this.filteredSteps.length - 1)
        }
      } else if (this.currentStepIndex > 0) {
        await this.setCurrentStep(this.currentStepIndex - 1)
      }
    },
    async next() {
      var result = null
      this.setStepLoaded(false)
      if (!this.stageHasNoFilteredSteps){
        result = await this.$refs['step-viewer'].saveStepData()
      }
      //TODO: Do we want to return a message if there is an error. Sounds good, where do we display it?
      // this.$bvToast.toast('Please address invalid fields before progressing.', {
      //   title: 'Error',
      //   variant: 'danger',
      // })
      if (result || this.stageHasNoFilteredSteps) {
        if (this.stepIsLast) {
          if (this.stageIsLast) {
            if (this.transitionToFormStageline()) {
              await this.$router.push({ name: 'stageline', params: { companyId: this.company.id, stagelineType: "form" } })
            } else {
              await this.$router.push({ name: 'stageline-page', params: { companyId: this.company.id } })
            }
          } else {
            await this.setCurrentStage(this.currentStageIndex + 1)
            await this.setCurrentStep(0)
          }
        } else {
          await this.setCurrentStep(this.currentStepIndex + 1)
        }
      } else {
        this.setStepLoaded(true)
      }
    },
    showContactModal() {
      this.$emit('show-contact-modal')
    },
    transitionToFormStageline() {
      return this.stagelineType === 'business_info'
        && !this.formedElsewhere
        && !this.stagelineComplete('form')
    },
  },
}
</script>

<style lang="scss" scoped>
  #stage-viewer-component {
    /* TODO for debugging */
    /*background: #69E2AA;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .stage-resource-container {
    text-align: left;
    padding: 0 0;
  }

  .next-button {
    float: right;
  }

  #no-steps-available {
    h1 {
      word-wrap: break-word;
      max-width: 90%;
    }
    .image-container {
      display: flex;
      justify-content: center;
      flex: 0 1 50%;
      picture {
        .image {
          height: 260px;
          margin-bottom: 20px;
        }
      }
    }
  }
</style>
