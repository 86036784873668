var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "resource-viewer" }, [
    _vm.viewerLoaded
      ? _c(
          "div",
          { staticClass: "resource-fields" },
          [
            _vm._l(_vm.fieldList, function (rf) {
              return _c(
                "div",
                [
                  rf.id === _vm.currentField.id && rf.field_type !== "radio"
                    ? _c(
                        "b-form-group",
                        { attrs: { label: _vm.resourceFieldLabel(rf) } },
                        [
                          rf.field_type === "text" ||
                          rf.field_type === "signature"
                            ? _c("b-form-input", {
                                staticClass: "input-field",
                                attrs: { type: rf.field_type },
                                model: {
                                  value:
                                    _vm.$refs["pdf-viewer"].formData[rf.id],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$refs["pdf-viewer"].formData,
                                      rf.id,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$refs['pdf-viewer'].formData[rf.id]",
                                },
                              })
                            : _vm._e(),
                          rf.field_type === "checkbox"
                            ? _c("b-form-checkbox", {
                                model: {
                                  value:
                                    _vm.$refs["pdf-viewer"].formData[rf.id],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$refs["pdf-viewer"].formData,
                                      rf.id,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$refs['pdf-viewer'].formData[rf.id]",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  rf.field_type === "radio" && rf.id === _vm.currentField.id
                    ? _c(
                        "b-form-radio-group",
                        { attrs: { label: _vm.radioButtonLabel(rf) } },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.radioButtonLabel(rf)) +
                              "\n        "
                          ),
                          _c("hr"),
                          _vm._l(
                            _vm.radioGroup(rf.data.button_group_id),
                            function (radioField) {
                              return _c(
                                "b-form-radio",
                                {
                                  key: radioField.id,
                                  attrs: {
                                    value: radioField.data.button_value,
                                  },
                                  model: {
                                    value:
                                      _vm.$refs["pdf-viewer"].formData[
                                        radioField.id
                                      ],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.$refs["pdf-viewer"].formData,
                                        radioField.id,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "$refs['pdf-viewer'].formData[radioField.id]",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(radioField.data.button_value) +
                                      "\n        "
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            _c(
              "div",
              { staticClass: "resource-viewer-navigation-buttons" },
              [
                _c(
                  "b-form-group",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          disabled:
                            _vm.currentFieldIndex === 0 &&
                            _vm.$refs["pdf-viewer"].currentPage === 1,
                          variant: "default",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.previousField()
                          },
                        },
                      },
                      [_vm._v("\n          Back\n        ")]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.nextField()
                          },
                        },
                      },
                      [_vm._v("\n          Next\n        ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "resource-pdf-viewer-container" },
      [
        _vm.resource
          ? _c("v1-resource-pdf-viewer", {
              ref: "pdf-viewer",
              staticStyle: { "border-style": "solid" },
              attrs: { "company-id": _vm.company.id, resource: _vm.resource },
              on: {
                loaded: _vm.viewerFinishedLoading,
                clicked: _vm.fieldClicked,
                "update:current-page": _vm.currentPageUpdated,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }