<template>
  <div id="stepline-component" :class="{ 'collapsed': collapsed }">
    <div v-if="stageLoaded && currentStage">
      <div id="stepline-exit-stageline">
        <router-link :to="{ name: 'root' }">
          <fa-icon icon="chevron-left" class="fa-xs" />
          <span v-if="!collapsed"> Exit Stageline</span>
          <span v-else> Exit</span>
        </router-link>
      </div>
      <div class="stepline-start-over mt-0 mb-3 flex-column">
        <b-button v-if="!collapsed"
                  id="start-over-button"
                  class="next-button"
                  variant="primary"
                  :disabled="false"
                  @click="showRestartStagelineConfirmationModal"
        >
          <span class="v-padding-point-5">
            Start Over
          </span>
        </b-button>
        <div v-else
             id="start-over-link"
             @click="showRestartStagelineConfirmationModal"
        >
          <span>
            Start
            Over
          </span>
        </div>
      </div>
      <div id="stepline-company-name">
        <h3 v-if="!collapsed">
          {{ company.name }}
        </h3>
        <h3 v-else /> <!-- no text but keep native layout attrs -->
        <template v-if="!collapsed && ['form', 'expand', 'dissolve'].includes(stagelineType)">
          <div id="stepline-current-jurisdiction">
            <span v-if="stagelineType === 'expand'">EXPANDING TO</span>
            <span v-if="stagelineType === 'form'">FORMING IN</span>
            <span v-if="stagelineType === 'dissolve'">DISSOLVING IN</span>
            <br>
            <span id="stepline-current-jurisdiction-name">{{ jurisdiction.state_province_region }}</span>
          </div>
        </template>
      </div>
      <div id="stepline-stage-number">
        STAGE {{ currentStageIndex + 1 }}
      </div>
      <div v-if="!collapsed" id="stepline-stage-title">
        {{ currentStage.title }}
      </div>
      <div id="stepline-step-list">
        <div v-for="step in filteredSteps"
             :key="step.id"
             class="step"
             :class="{
               'previous': stepIsPrevious(step),
               'active': stepIsCurrent(step),
               'complete': stepIsComplete(step) && !stepIsCurrent(step),
             }"
             @click="gotoStep(step)"
        >
          <div class="indicator">
            <stage-complete-icon v-if="stepIsComplete(step) && !stepIsCurrent(step)" />
            <stage-current-icon v-if="stepIsCurrent(step)" />
          </div>
          <div v-if="!collapsed" class="mobile-font">
            {{ step.name || step.heading }}
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="!stageLoaded" class="d-flex justify-content-center">
      <ct-centered-spinner variant="primary" />
    </div>

    <restart-stageline-confirmation-modal
      id="restart-stageline-confirmation-modal"
      ref="restart-stageline-confirmation-modal"
      @reset="startOverStageLine"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CtCenteredSpinner from '../shared/CtCenteredSpinner'
import StageCurrentIcon from '@images/ui/step-current.svg'
import StageCompleteIcon from '@images/ui/step-complete.svg'
import RestartStagelineConfirmationModal from '@/components/StagelineV1/modals/RestartStagelineConfirmationModal'


export default {
  name: 'Stepline',
  components: {
    CtCenteredSpinner,
    StageCurrentIcon,
    StageCompleteIcon,
    RestartStagelineConfirmationModal,
  },
  props: {
    collapsed: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
      'jurisdiction',
      'currentStageIndex',
      'currentStage',
      'currentStageCompletedStepIds',
      'currentStep',
      'currentStepIndex',
      'filteredSteps',
      'stageLoaded',
      'stagelineType',
      'startOver',
    ]),
  },
  methods: {
    ...mapActions('stageline', [
      'setCurrentStep',
      'setStartOver',
      'clearCompanyStages',
    ]),
    ...mapActions('checkout', ['clearCart']),
    stepIsPrevious(step) {
      return this.filteredSteps.findIndex(s => s.id === step.id) < this.currentStepIndex
    },
    stepIsCurrent(step) {
      return this.filteredSteps.findIndex(s => s.id === step.id) === this.currentStepIndex
    },
    stepIsComplete(step) {
      return this.currentStageCompletedStepIds.includes(step.id)
    },
    gotoStep(step) {
      this.setCurrentStep(this.filteredSteps.findIndex(s => s.id === step.id))
    },
    showRestartStagelineConfirmationModal(){
      this.$refs['restart-stageline-confirmation-modal'].show()
    },
    async startOverStageLine() {
      this.$refs['restart-stageline-confirmation-modal'].hide()
      await this.clearCart()
      await this.setStartOver(true)
      await this.clearCompanyStages(this.company.id)
      await this.$router.push({ name: 'root' })
    },
  },
}
</script>

<style scoped lang="scss">
#stepline-component {
  padding: 100px 20px 0 30px;
  color: $ct-ui-font-color-default-grey;

  #stepline-exit-stageline {
    position: absolute;
    top: 30px;
    left: 30px;
    font-size: smaller;
    font-weight: 900;
    a {
      color: $ct-ui-font-color-default-grey;
    }
  }
  #stepline-company-name {
    h3 {
      margin: 0 0 10px 0;
    }
    margin-bottom: 50px;
  }
  #stepline-current-jurisdiction,
  #stepline-stage-number {
    font-size: 12px !important;
    color: $ct-ui-font-color-secondary;
    font-weight: $ct-ui-font-weight-9;
    letter-spacing: 1.2px;
    #stepline-current-jurisdiction-name {
      font-size: 1.5em;
    }
  }
  #start-over-link {
    font-size: smaller;
    font-weight: 900;
    color: $ct-ui-font-color-default-grey;
  }
  #start-over-link:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  #stepline-stage-title {
    margin-bottom: 18px;
    font-size: 13px;
    font-weight: 900;
  }
  #stepline-step-list {
    margin-left: 28px;
    //letter-spacing: -0.5px;
    font-size: 14px;
    position: absolute;
    .step {
      cursor: pointer;
      margin: 0 0 18px 0;
      position: relative;
      //color: $ct-ui-primary;
      max-width: 210px;
      min-height:20px;
      :hover {
        text-decoration: underline;
      }
      .indicator {
        position: absolute;
        left: -24px;
        top: 2px;
        background-color: transparent;
        border-radius: 50%;
        border: $ct-ui-stageline-graphic-linewidth solid $ct-ui-stepline-indicator-color;
        width: 18px;
        height: 18px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &.previous {
        //font-weight: 900 !important;
      }
      &.active {
        font-weight: 900;
        .indicator {
          background: $ct-ui-stepline-indicator-color-light;
          svg {
            overflow: visible;
            height: 22px !important;
            position: relative;
            left: 1px;
            path {
              fill: $ct-ui-stepline-indicator-color !important;
              stroke-width: 6 !important;
            }
          }
        }
      }
      &.complete {
        .indicator {
          background: $ct-ui-stepline-indicator-color;
          svg {
            overflow: visible;
            height: 26px !important;
            path {
              stroke-width: 6 !important;
            }
          }
        }
      }
    }
  }

  .active .indicator {
    background-color: $ct-ui-stepline-indicator-color-light;
    border: 1px solid $ct-ui-stepline-indicator-color;
    border-radius: 50%;
  }
  .complete .indicator {
    background-color: $ct-ui-stepline-indicator-color;
    border: 1px solid $ct-ui-stepline-indicator-color;
    border-radius: 50%;
  }
  &.collapsed {
    position: relative;
    right: 10px;
    #stepline-stage-number {
      position: relative;
      right: 4px;
      width: max-content;
    }
    #stepline-step-list {
      margin-left: 34px;
      margin-top: 20px;
    }
  }
}

.v-padding-point-5 {
  padding: 0 0.5rem;
}

@media only screen and (max-width: 800px) {
  #stepline-component {
    padding: 1.0em;
    height: 100%;

    display: flex;
    flex-flow: column nowrap;

    #stepline-exit-stageline {
      position: static;
      top: unset;
      left: unset;
      padding-bottom: 1.0em;
    }

    .stepline-start-over {
      padding-bottom: 1.0em;
      margin-bottom: 0 !important;

      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: center;

      #start-over-button {
        font-size: 0.8em !important;
        padding: 0.5em !important;
      }
    }

    #stepline-company-name {
      margin-bottom: 1.0em;

      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;

      h3 {
        font-size: 1.2em;
      }

      #stepline-current-jurisdiction {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        column-gap: 0.2em;

        #stepline-current-jurisdiction-name {
          font-size: 1.2em;
        }
      }
    }

    #stepline-step-list {
      position: static;
      margin-left: 0;
      padding: 0 0 1.0em 2.0em;

      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;

      .step {
        width: 100%;
        position: static;
        margin-bottom: 0;
        padding-bottom: 0.5em;
        font-size: 0.875em;

        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;

        .indicator {
          position: static;
          left: unset;
          top: unset;
          margin-right: 0.4em;
        }
      }
    }

    &.collapsed {
      display: none;
    }
  }
}
</style>
