<template>
  <div class="resource-viewer">
    <div v-if="viewerLoaded" class="resource-fields">
      <div v-for="rf in fieldList">
        <b-form-group v-if="rf.id === currentField.id && rf.field_type !== 'radio'" :label="resourceFieldLabel(rf)">
          <b-form-input
            v-if="rf.field_type === 'text' || rf.field_type === 'signature'"
            v-model="$refs['pdf-viewer'].formData[rf.id]"
            class="input-field"
            :type="rf.field_type"
          />
          <b-form-checkbox v-if="rf.field_type === 'checkbox'" v-model="$refs['pdf-viewer'].formData[rf.id]" />
        </b-form-group>
        <!--This is a bit weird, but essentially we just find the first radio in a group and render the whole group with that one reference and ignore the rest-->
        <b-form-radio-group v-if="rf.field_type === 'radio' && rf.id === currentField.id" :label="radioButtonLabel(rf)">
          {{ radioButtonLabel(rf) }}
          <hr>
          <b-form-radio
            v-for="radioField in radioGroup(rf.data.button_group_id)"
            :key="radioField.id"
            v-model="$refs['pdf-viewer'].formData[radioField.id]"
            :value="radioField.data.button_value"
          >
            {{ radioField.data.button_value }}
          </b-form-radio>
        </b-form-radio-group>
      </div>
      <div class="resource-viewer-navigation-buttons">
        <b-form-group>
          <b-button :disabled="currentFieldIndex === 0 && $refs['pdf-viewer'].currentPage === 1"
                    variant="default"
                    @click="previousField()"
          >
            Back
          </b-button>
          <b-button variant="primary" @click="nextField()">
            Next
          </b-button>
        </b-form-group>
      </div>
    </div>
    <div class="resource-pdf-viewer-container">
      <v1-resource-pdf-viewer
        v-if="resource"
        ref="pdf-viewer"
        :company-id="company.id"
        :resource="resource"
        style="border-style: solid;"
        @loaded="viewerFinishedLoading"
        @clicked="fieldClicked"
        @update:current-page="currentPageUpdated"
      />
    </div>
  </div>
</template>

<script>
import V1ResourcePdfViewer from '@/components/V1ResourcePDFViewer'

export default {
  name: 'V1ResourceSideBySideViewer',
  components: {
    V1ResourcePdfViewer,
  },
  props: {
    resource: Object,
    company: Object,
  },
  data() {
    return {
      viewerLoaded: false,
      fieldList: null,
      currentField: {},
      currentFieldIndex: 0,
      needsSynchronized: false,
    }
  },
  async mounted() {
    this.configureFieldList()
    this.currentField = this.fieldList[this.currentFieldIndex]
  },
  methods: {
    viewerFinishedLoading() {
      this.viewerLoaded = true
    },
    completed() {
      this.$emit('completed', this.$refs['pdf-viewer'].formData)
    },
    configureFieldList() {
      this.fieldList = this.$refs['pdf-viewer'].fields.filter(f => (f.description && f.description !== '') || (f.field_type === 'radio'))
    },
    fieldClicked(rf) {
      if (this.fieldList.indexOf(rf) !== -1) {
        this.currentFieldIndex = this.fieldList.indexOf(rf)
        this.currentField = rf
      }
    },
    currentPageUpdated() {
      this.needsSynchronized = true
    },
    radioGroup(id) {
      return this.fieldList.filter(f => f.data && f.data.button_group_id === id)
    },
    radioButtonLabel(rf) {
      // This allows the admins to just throw a prompt on any individual field
      let label = 'Please select a following option: '

      if (rf.description && rf.description !== '') {
        label = rf.description
      } else {
        this.radioGroup(rf.id).forEach(radioField => {
          if (radioField.description && radioField.description !== '') {
            label = radioField.description
          }
        })
      }

      return label
    },
    resourceFieldLabel(rf) {
        if (rf.description && rf.description !== '') {
          return rf.description
        } else {
          return rf.original_field_label
        }
    },
    previousField() {
      if (this.needsSynchronized) {
        this.synchronizePageWithField()
      }
      this.currentFieldIndex--

      if (this.currentField.field_type === 'radio') {
        while (this.fieldList[this.currentFieldIndex].data && this.fieldList[this.currentFieldIndex].data.button_group_id === this.currentField.data.button_group_id) {
          this.currentFieldIndex--
        }
      }

      if (this.currentFieldIndex >= 0) {
        this.currentField = this.fieldList[this.currentFieldIndex]
      } else {
        this.paginateBack()

        this.configureFieldList()
        this.currentFieldIndex = this.fieldList.length - 1
        this.currentField = this.fieldList[this.currentFieldIndex]
      }

      this.setCurrentFieldFocus()
    },
    nextField() {
      if (this.needsSynchronized) {
        this.synchronizePageWithField()
      }
      this.currentFieldIndex++

      if (this.currentField.field_type === 'radio') {
        while (this.fieldList[this.currentFieldIndex] &&
               this.fieldList[this.currentFieldIndex].data &&
               this.fieldList[this.currentFieldIndex].data.button_group_id === this.currentField.data.button_group_id) {
          this.currentFieldIndex++
        }
      }

      if (this.fieldList[this.currentFieldIndex]) {
        this.currentField = this.fieldList[this.currentFieldIndex]
        this.setCurrentFieldFocus()
      } else {
        if (this.$refs['pdf-viewer'].currentPage === this.resource.pages.length) {
          this.completed()
        } else {
          this.paginateForward()
          if (this.$refs['pdf-viewer'].fields.filter(f => (f.description && f.description !== '')).length === 0) {
            this.completed()
          } else {
            this.currentFieldIndex = 0
            this.configureFieldList()
            this.currentField = this.fieldList[this.currentFieldIndex]
          }
        }
      }
    },
    synchronizePageWithField() {
      this.$refs['pdf-viewer'].currentPage = (this.currentField.page + 1)
      this.$refs['pdf-viewer'].paginationKey++
    },
    paginateBack() {
      this.$refs['pdf-viewer'].currentPage--
      this.$refs['pdf-viewer'].paginationKey++
    },
    paginateForward() {
      this.$refs['pdf-viewer'].currentPage++

      // Documents can have pages that are entirely optional so we jump to the next page with a field that has a prompt.
      while (this.$refs['pdf-viewer'].fields.filter(f => f.description && f.description !== '').length === 0 && (this.$refs['pdf-viewer'].currentPage <= this.$refs['pdf-viewer'].pages.length)) {
        this.$refs['pdf-viewer'].currentPage++
      }

      if (this.$refs['pdf-viewer'].currentPage > this.$refs['pdf-viewer'].pages.length) {
        // If this gets hit it means we completed the document somewhere other than the last page, implying the rest of the pages are optional.
        this.$refs['pdf-viewer'].currentPage = (this.currentField.page + 1)
        this.completed()
      } else {
        this.$refs['pdf-viewer'].paginationKey++
      }
    },
    setCurrentFieldFocus() {
      if (this.$refs['pdf-viewer'].$refs[this.currentField.id].length > 0) {
        this.$refs['pdf-viewer'].$refs[this.currentField.id][0].focus()
      }
    },
  },
}
</script>

<style scoped lang="scss">

  $gridSize: 90vMin;

  .resource-viewer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .input-field {
      border-color: #80bdff;
    }

    .pdf-field input {
      font-family: 'Consolas', 'Inconsolata', 'Monaco', "Courier";
      font-size: 0.7vw !important;
      &:focus {
        box-shadow: 0px 0px 1px 2px red;
      }
    }

    .resource-fields {
      flex: 0 1 30%;
      padding-right: 20px;
      min-width: 300px;
      @media only screen and (max-width: 1168px) {
        width: 100% !important;
        flex: 0 1 100%;
      }
    }
    .resource-pdf-viewer-container {
      flex: 0 1 70%;
      @media only screen and (max-width: 1168px) {
        width: 100% !important;
        flex: 0 1 100%;
      }
    }

    .resource-viewer-navigation-buttons {
      justify-content: flex-end;
      float: right;
    }
  }
</style>
