<template>
  <b-modal
    id="restart-stageline-confirmation-modal"
    ref="restart-stageline-confirmation-modal"
    title="Warning:"
    header-class="no-borders"
    size="md"
    hide-footer
  >
    <p>Resetting your Stageline progress will remove all completed steps/stages and you will restart from the beginning.</p>
    <b-button variant="danger" @click="resetStagelineProgress">
      Reset
    </b-button>
    <b-button variant="primary" class="float-right" @click="cancel">
      Cancel
    </b-button>
  </b-modal>
</template>

<script>
export default {
  name: "RestartStagelineConfirmationModal",
  methods: {
    show(){
      this.$refs['restart-stageline-confirmation-modal'].show()
    },
    hide(){
      this.$refs['restart-stageline-confirmation-modal'].hide()
    },
    resetStagelineProgress(){
      this.$emit('reset')
    },
    cancel(){
      this.hide()
    },
  },
}
</script>
