var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentStage && _vm.stageLoaded
    ? _c(
        "div",
        { attrs: { id: "stage-viewer-component" } },
        [
          !_vm.stageHasNoFilteredSteps
            ? _c("step-viewer", {
                ref: "step-viewer",
                attrs: {
                  "show-back-button": _vm.showBackButton,
                  "show-continue-button": _vm.showContinueButton,
                },
                on: {
                  back: _vm.back,
                  continue: _vm.next,
                  "show-contact-modal": _vm.showContactModal,
                  "proceed-to-next-step": _vm.next,
                },
              })
            : _vm._e(),
          _vm.stageHasNoFilteredSteps
            ? _c(
                "div",
                { attrs: { id: "no-steps-available" } },
                [
                  _c("h1", { staticStyle: { margin: "0 0 10px 0" } }, [
                    _vm._v(
                      "\n      There's nothing left to do on this stage.\n    "
                    ),
                  ]),
                  _c("p", { staticStyle: { "margin-bottom": "40px" } }, [
                    _vm._v(
                      "\n      Looks like everything on this stage has been taken care of.\n      Click to continue your company stageline.\n    "
                    ),
                  ]),
                  _c("div", { staticClass: "image-container" }, [
                    _c("picture", [
                      _c("source", {
                        attrs: {
                          srcset: require("@images/illustrations/stageline/stageline-continue-sign.webp"),
                          type: "image/webp",
                        },
                      }),
                      _c("img", {
                        staticClass: "image",
                        attrs: {
                          src: require("@images/illustrations/stageline/stageline-continue-sign.png"),
                          alt: "Continue",
                        },
                      }),
                    ]),
                  ]),
                  _c(
                    "b-button",
                    {
                      staticClass: "next-button",
                      attrs: { variant: "primary" },
                      on: { click: _vm.next },
                    },
                    [
                      _c("span", { staticStyle: { padding: "0 .5rem" } }, [
                        _vm._v("\n        Continue\n      "),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : !_vm.stageLoaded
    ? _c(
        "div",
        { staticClass: "d-flex flex-row justify-content-center" },
        [_c("ct-centered-spinner", { attrs: { label: "Loading..." } })],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }