var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "step-viewer-component" } },
    [
      _vm.stepLoaded
        ? [
            _vm.stepLayoutType === "resting"
              ? _c(
                  "resting-step",
                  _vm._g(
                    { ref: "resting-step", attrs: { step: _vm.currentStep } },
                    _vm.$listeners
                  )
                )
              : _vm.stepLayoutType === "checkout"
              ? _c(
                  "checkout-step",
                  _vm._g({ ref: "checkout-step" }, _vm.$listeners)
                )
              : _vm.stepLayoutType === "product"
              ? _c(
                  "product-step",
                  _vm._g(
                    {
                      ref: "product-step",
                      attrs: { "step-products": _vm.currentStep.products },
                    },
                    _vm.$listeners
                  )
                )
              : _vm.stepLayoutType === "document"
              ? _c(
                  "document-step",
                  _vm._g(
                    {
                      key: _vm.currentStep.id,
                      ref: "document-step",
                      attrs: { step: _vm.currentStep, company: _vm.company },
                      on: { "document-complete": _vm.documentComplete },
                    },
                    _vm.$listeners
                  )
                )
              : _c(
                  "basic-step",
                  _vm._g(
                    {
                      ref: "basic-step",
                      attrs: {
                        step: _vm.currentStep,
                        company: _vm.company,
                        "selected-jurisdiction": _vm.jurisdiction,
                      },
                      on: { "utility-complete": _vm.handleUtilityComplete },
                    },
                    _vm.$listeners
                  )
                ),
          ]
        : !_vm.stepLoaded
        ? _c(
            "div",
            { staticClass: "d-flex flex-row justify-content-center" },
            [_c("ct-centered-spinner", { attrs: { label: "Loading..." } })],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSlideControls,
              expression: "showSlideControls",
            },
          ],
          staticClass: "mt-3 mb-0 step-viewer-button-container",
        },
        [
          _c(
            "span",
            [
              _vm.showUtilityButton
                ? _c(
                    "b-button",
                    {
                      staticClass: "btn utility-button btn-preferred",
                      attrs: { disabled: _vm.utilityButtonDisabled },
                      on: { click: _vm.utilityPressed },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.utilityButtonText) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            { staticClass: "step-viewer-button-column" },
            [
              _vm.showBackButton && !_vm.currentStep.config.back_button.hide
                ? _c(
                    "b-button",
                    { attrs: { variant: "default" }, on: { click: _vm.back } },
                    [
                      _c("fa-icon", {
                        staticClass: "fa-xs",
                        attrs: { icon: "chevron-left" },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.currentStep.config.back_button.text ||
                              "Previous"
                          ) +
                          "\n      "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showContinueButton &&
              !_vm.currentStep.config.continue_button.hide
                ? _c(
                    "b-button",
                    {
                      staticClass: "next-button",
                      attrs: { disabled: !_vm.stepLoaded, variant: "primary" },
                      on: { click: _vm.next },
                    },
                    [
                      _vm.stepLoaded
                        ? _c("span", { staticStyle: { padding: "0 .5rem" } }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.continueButtonText) +
                                "\n        "
                            ),
                          ])
                        : _c("b-spinner", {
                            attrs: { small: "", variant: "light" },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm.errorMessage
        ? _c("div", { staticClass: "errorMessage" }, [
            _vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  "),
          ])
        : _vm._e(),
      _c("br"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }