<template>
  <div>
    <b-modal id="multiple-product-selection-modal"
             title="Please select"
             hide-footer
    >
      <selection-cards
        :items="availableProducts"
        @chosen="productChosen"
      />
    </b-modal>
    <b-modal id="product-confirm-modal"
             hide-footer
             hide-header
             size="lg"
    >
      <filing-options
        v-if="productKind === 'filing_product'"
        submission-label="Add"
        :product="selectedProduct"
        :company="company"
        :disable-domestic-form="true"
        :saved-items="[]"
        @submit="addFilingToCart"
        @closeModal="closeProductOptionsModel()"
        @remove-by-id=""
        @remove-by-jurisdiction=""
      />
      <simple-checkout
        v-if="productKind === 'simple_product'"
        :product="selectedProduct"
        :company="company"
        @completed="addProductToCart"
      />
      <ct-centered-spinner v-if="loading" />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FilingOptions from "@/components/HireUs/FilingOptions"
import SimpleCheckout from "@/components/HireUs/SimpleCheckout"
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'
import SelectionCards from '@/components/shared/SelectionCards'
import { FILINGS_PACKAGED_WITH_RENEWAL_SERVICE } from '@/common/modules/constants'

export default {
  name: 'ProductOptionsConfirmModal',
  components: {
    FilingOptions,
    SimpleCheckout,
    SelectionCards,
    CtCenteredSpinner,
  },
  props: {
    company: Object,
    loading: Boolean,
    bus: Object,
  },
  data() {
    return {
      availableProducts: [],
      selectedProduct: null,
    }
  },
  computed: {
    ...mapGetters('jurisdictions', ['findByAbbreviation']),
    ...mapGetters('checkout', [
      'findCartItem',
      'registeredAgentProductByJurisdiction',
      'deduceCartItemJurisdiction',
      'renewalService',
    ]),
    ...mapGetters('companies', ['hasExistingRAServiceInJurisdiction']),
    productKind() {
      return this.selectedProduct?.kind
    },
  },
  async mounted() {
    this.bus.$on('open-product-options-confirm', this.open)
  },
  methods: {
    ...mapActions('checkout', [
      'addToCart',
      'addFreeTaxIdToCart',
      'checkForRACartItemAndRemove',
    ]),
    async open(products) {
      this.availableProducts = products
      if (!products || products.length == 0) {
        return
      }
      this.selectedProduct = null
      if (this.availableProducts.length > 1) {
        this.$bvModal.show('multiple-product-selection-modal')
      } else {
        await this.productChosen(this.availableProducts[0])
      }
    },
    productChosen(product) {
      this.selectedProduct = product
      this.selectedProduct.skipResolveOptionalItems = true //Need this set early for simple-checkout
      this.$bvModal.hide('multiple-product-selection-modal')

      if (product.kind === 'filing_product' || product.kind === 'simple_product') {
        this.$bvModal.show('product-confirm-modal')
      } else {
        this.addProductToCart()
      }
    },
    async addFilingToCart(info) {
      this.selectedProduct = info.product
      this.selectedProduct.filingMethodId = info.filingMethod.id
      await this.addProductToCart()
    },
    async addProductToCart() {
      switch (this.selectedProduct.kind) {
        case 'simple_product': {
          //Already added to cart in simple-checkout
          this.$bvModal.hide('product-confirm-modal')
          this.complete()
          return
        }
        case 'filing_product': {
          this.$bvModal.hide('product-confirm-modal')
          break
        }
      }
      const addedToCart = await this.addToCart(this.selectedProduct)

      // Address situations where the item is not added to cart due to Smart/Stupid Cart Logic
      if (addedToCart) {
        const cartItemProduct = this.findCartItem(this.selectedProduct.id)
        const jurisdiction = this.deduceCartItemJurisdiction(cartItemProduct)
        if (['form a company', 'register a company'].includes(this.selectedProduct.filing_name)) {
          if (!this.hasExistingRAServiceInJurisdiction(jurisdiction)) {
            const raProduct = this.registeredAgentProductByJurisdiction(jurisdiction.state_province_region)

            await this.addToCart({
              ...raProduct,
              kind: 'registered_agent_product',
              parentItemId: cartItemProduct.id,
              skipResolveOptionalItems: true,
            })
          }
        }

        if (FILINGS_PACKAGED_WITH_RENEWAL_SERVICE.includes(this.selectedProduct.filing_name)) {
          const renewalService = this.renewalService

          await this.addToCart({
            ...renewalService,
            kind: 'simple_product',
            parentItemId: cartItemProduct.id,
            data: { state: jurisdiction.state_province_region },
            skipResolveOptionalItems: true,
          })
        }

        if (this.selectedProduct.filing_name === 'free form a company') {
          await this.addFreeTaxIdToCart(cartItemProduct)
          await this.checkForRACartItemAndRemove(jurisdiction)
        }

        this.complete()
      }
    },
    complete() {
      this.$emit('complete')
    },
    closeProductOptionsModel() {
      this.$bvModal.hide('product-confirm-modal')
    },
  },
}
</script>
