var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "multiple-product-selection-modal",
            title: "Please select",
            "hide-footer": "",
          },
        },
        [
          _c("selection-cards", {
            attrs: { items: _vm.availableProducts },
            on: { chosen: _vm.productChosen },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "product-confirm-modal",
            "hide-footer": "",
            "hide-header": "",
            size: "lg",
          },
        },
        [
          _vm.productKind === "filing_product"
            ? _c("filing-options", {
                attrs: {
                  "submission-label": "Add",
                  product: _vm.selectedProduct,
                  company: _vm.company,
                  "disable-domestic-form": true,
                  "saved-items": [],
                },
                on: {
                  submit: _vm.addFilingToCart,
                  closeModal: function ($event) {
                    return _vm.closeProductOptionsModel()
                  },
                  "remove-by-id": function ($event) {},
                  "remove-by-jurisdiction": function ($event) {},
                },
              })
            : _vm._e(),
          _vm.productKind === "simple_product"
            ? _c("simple-checkout", {
                attrs: { product: _vm.selectedProduct, company: _vm.company },
                on: { completed: _vm.addProductToCart },
              })
            : _vm._e(),
          _vm.loading ? _c("ct-centered-spinner") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }