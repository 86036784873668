var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "pdf-download-modal",
      attrs: { id: "pdf-download-modal", "hide-footer": "" },
    },
    [
      _c("b-form-group", [
        _c("span", [
          _vm._v(
            "You have completed filling out the necessary information for this document! Now you can download the form for submission: "
          ),
        ]),
        _c("hr"),
        _c(
          "div",
          [
            _c(
              "b-button",
              {
                attrs: { variant: "danger" },
                on: {
                  click: function ($event) {
                    return _vm.submit(true)
                  },
                },
              },
              [_vm._v("\n        Skip Download\n      ")]
            ),
            _c(
              "b-button",
              {
                staticClass: "float-right",
                attrs: { variant: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.submit(true)
                  },
                },
              },
              [_vm._v("\n        Download\n      ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }